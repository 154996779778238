<template>
  <v-card class="mx-auto" elevation="0">
    <v-card-title class="text-left card-header fixed-card-title2">
    {{ $t("edit-patient") }}
    <v-spacer></v-spacer>
    <v-icon color="white" @click="closeDrawer">mdi mdi-close</v-icon>
    </v-card-title>
    <v-card-text class="px-2 mt-2">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-row dense>
            <v-col class="d-flex pa-1" cols="12" sm="6">
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  placeholder="SomeName"
                  v-model="initialName"
                  :rules="[(v) => !!v || 'Item is required']"
                  :label="$t('name')"
                  required
              ></v-text-field>
            </v-col>
            <v-col class="d-flex pa-1" cols="12" sm="6">
              <v-text-field
                  outlined
                  :rules="[(v) => !!v || 'Item is required']"
                  hide-details="auto"
                  dense
                  v-model="initialSurname"
                  :label="$t('surname')"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="d-flex align-center justify-space-between pa-1" cols="12" sm="6">
              <v-btn
                  small
                  class="width50 px-1"
                  :class="{
            'male-button': detailedUser.userData.gender === 'M',
            'female-button': detailedUser.userData.gender === 'F',
            'chips': detailedUser.userData.gender === 'M',
            'text-color-white': detailedUser.userData.gender === 'M', // White text if active
            'text-color-black': detailedUser.userData.gender !== 'M' // Black text if inactive
        }"
                  @click="detailedUser.userData.gender = 'M'"
              >
                {{$t('male')}}
              </v-btn>

              <v-btn
                  small
                  class="width50 px-1"
                  :class="{
            'male-button': detailedUser.userData.gender === 'M',
            'female-button': detailedUser.userData.gender === 'F',
            'chips': detailedUser.userData.gender === 'F',
            'text-color-white': detailedUser.userData.gender === 'F', // White text if active
            'text-color-black': detailedUser.userData.gender !== 'F' // Black text if inactive
        }"
                  @click="detailedUser.userData.gender = 'F'"
              >
                {{$t('female')}}
              </v-btn>
            </v-col>
            <v-col class="d-flex pa-1" cols="12" sm="6">
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="initialPhone"
                  @input="formatPhoneNumber"
                  :label="$t('phone')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="d-flex pa-1" cols="12" sm="6">
              <v-text-field
                  :label="$t('email')"
                  v-model="initialEmail"
                  dense
                  :rules="[(v) => !!v || 'Item is required']"
                  hide-details
                  outlined
              ></v-text-field>
            </v-col>
            <v-col class="d-flex pa-1" cols="12" sm="6">
              <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="userData.patientMetadata.medicalRegistrationNumber"
                  :label="$t('mrn')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="d-flex pa-1" cols="12" sm="6">
              <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="userData.patientMetadata.memberIdPolicyNumber"
                  :label="$t('policyNumber')"
              ></v-text-field>
            </v-col>
            <v-col class="d-flex pa-1" cols="12" sm="6">
              <v-select
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[v => !!v || 'Item is required']"
                  hide-details
                  dense
                  item-value="lowValue"
                  :items= "getHealthInsuranceProgram"
                  :item-text= "domain=> domain.shortDescription"
                  v-model="userData.patientMetadata.healthInsurance"
                  :label="$t('insurance')"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row dense >
            <v-col class="d-flex pa-1" cols="12" sm="6">
              <v-text-field
                  type="number"
                  :label="userSettings.Units === 'METRIC' ? $t('height-in-cm') : $t('height-in-ft')"
                  v-model="heightInCm"
                  outlined
                  dense
                  hide-details="auto"
                  :error-messages="error"
                  @input="checkValidation"
              ></v-text-field>
            </v-col>
            <v-col class="d-flex pa-1" cols="12" sm="6">
              <v-text-field
                  :label="userSettings.Units === 'METRIC' ? $t('weight-in-kg') : $t('weight-in-lbs')"
                  v-model="weightInKg"
                  outlined
                  dense
                  hide-details="auto"
                  @input="checkWeight"
                  :error-messages="weightLimit"
                  type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <BirthdatePicker :inputValue.sync="userData.birthdateAsString" />
            </v-col>
          </v-row>
          <v-row dense class="d-flex pa-1" cols="12">
            <v-text-field
                v-model="userData.address"
                :label="$t('address')"
                outlined
                hide-details
                dense
                :rules="[(v) => !!v || 'Item is required']"
            ></v-text-field>
          </v-row>
          <v-row dense>
            <v-col class="d-flex pa-1" cols="6">
              <v-text-field
                  outlined
                  hide-details
                  dense
                  :rules="[(v) => !!v || 'Item is required']"
                  :label="$t('city')"
                  v-model="userData.city"
                  required
              ></v-text-field>

            </v-col>
            <v-col class="d-flex pa-1" cols="6">
              <v-text-field
                  outlined
                  hide-details
                  dense
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('state')"
                  v-model="userData.state"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="d-flex pa-1" cols="12" sm="6">
              <v-text-field
                  v-model="userData.zip"
                  :label="$t('zip')"
                  outlined
                  hide-details
                  dense
                  :rules="[(v) => !!v || 'Item is required']"
              ></v-text-field>

            </v-col>
            <v-col class="d-flex pa-1" cols="12" sm="6">
              <v-text-field
                  outlined
                  hide-details
                  dense
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('country')"
                  v-model="userData.country"
                  required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense class="d-flex justify-space-between my-2 mx-0">
            <v-btn-toggle
                v-model="userData.language"
                row
                active-class="chips white--text"
                mandatory
                class="white"
            >
              <v-btn
                  class="patientEdit-button-style"
                  value='ENG'
              >{{$t("english")}}</v-btn>
              <v-btn
                  class="patientEdit-button-style"
                  value='ESP'
              >{{$t("spanish")}}</v-btn>
            </v-btn-toggle>
            <v-btn-toggle
                row
                mandatory
                v-model="scaleAndTempUnit"
                class="white "
                active-class="chips white--text"
            >
              <v-btn
                  class="patientEdit-button-style"
                  value='IMPERIAL'
                  @click="setImperialOrMetric('IMPERIAL')"
              >{{$t("imperial")}}</v-btn>
              <v-btn
                  class="patientEdit-button-style"
                  value='METRIC'
                  @click="setImperialOrMetric('METRIC')"
              >{{$t("metric")}}</v-btn>
            </v-btn-toggle>
          </v-row>
          <v-row class="d-flex justify-space-between my-2 mx-0" dense>
            <v-btn-toggle
                v-model="smokerCheck"
                row
                active-class="chips white--text"
                mandatory
                class="white"
            >
              <v-btn
                  class="patientEdit-button-style"
                  value='0'
              >{{$t("non-smoker")}}</v-btn>
              <v-btn
                  class="patientEdit-button-style"
                  value='1'
              >{{$t("smoker")}}</v-btn>
            </v-btn-toggle>
            <v-btn-toggle
                row
                active-class="chips white--text"
                mandatory
                v-model="glucoseAndCholesterolUnit"
                class="white"
            >
              <v-btn
                  class="patientEdit-button-style"
                  value='mg/dl'
                  @click="setGlucoseAndCholesterolUnit('mg/dl')"
              >MG/DL</v-btn>
              <v-btn
                  class="patientEdit-button-style"
                  value='mmol/l'
                  @click="setGlucoseAndCholesterolUnit('mmol/l')"
              >MMOL/L</v-btn>
            </v-btn-toggle>
          </v-row>
          <v-row class="mb-3 px-2 d-flex justify-center">
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <div class="d-flex flex-column"    v-if="!(userDetails.isSequoiaUser === false || userDetails.isSequoiaUser === null)">
                <span class="font-weight-bold color-black">{{$t('enable-rpm')}}</span>
                <v-btn-toggle
                    v-model="enableRPM"
                    row
                    active-class="chips white--text"
                    mandatory
                    class="white"
                >
                  <v-btn
                      class="tabs-class"
                      :value="1"
                      :input-value="enableRPM"
                  >{{$t("yes")}}</v-btn>
                  <v-btn
                      class="tabs-class"
                      :value="0"
                      :input-value="enableRPM"

                  >{{$t("no")}}</v-btn>
                </v-btn-toggle>
              </div>
            </v-col>
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <div class="d-flex flex-column " v-if="!(userDetails.isSequoiaUser === false || userDetails.isSequoiaUser === null)">
                <span class="font-weight-bold color-black">{{$t('enable-rtm')}}</span>
                <v-btn-toggle
                    v-model="enableRTM"
                    row
                    active-class="chips white--text"
                    mandatory
                    class="white"
                >
                  <v-btn
                      class="tabs-class"
                      :value="1"
                      :input-value="enableRTM"
                  >{{$t("yes")}}</v-btn>
                  <v-btn
                      class="tabs-class"
                      :value="0"
                      :input-value="enableRTM"
                  >{{$t("no")}}</v-btn>
                </v-btn-toggle>

              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex justify-center mt-n2"
            >
              <div class="d-flex flex-column" v-if="!(userDetails.isSequoiaUser === false || userDetails.isSequoiaUser === null)">
                <span class="font-weight-bold color-black">{{$t('mental-health')}}</span>
                <v-btn-toggle
                    v-model="mentalHealth"
                    row
                    active-class="chips white--text"
                    mandatory
                    class="white"
                >
                  <v-btn
                      class="tabs-class"
                      :value="1"
                      :input-value="mentalHealth"
                  >{{$t("yes")}}</v-btn>
                  <v-btn
                      class="tabs-class"
                      :value="0"
                      :input-value="mentalHealth"

                  >{{$t("no")}}</v-btn>
                </v-btn-toggle>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions class="fixed-card-actions">
      <v-row class="mb-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer()"
              elevation="0"
              class="capitalize-text cancel-button-style"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            :disabled="!valid"
            class="save-button-style"
            @click="updatePatientData"
            :loading="saveLoader"
          >
            {{ $t("save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { closeWhite } from '@/assets/index';
import { fromTo } from '@/utils/unit-converter.js';
import { mapState, mapGetters } from 'vuex';
import { AsYouType } from 'libphonenumber-js';
import BirthdatePicker from '@/views/Client/components/BirthdatePicker.vue';
import { showErrorAlert, showSuccessAlert } from '@/utils/utilities';

export default {
	name: 'DashboardCoreAppBar',
	components: {
    BirthdatePicker,
  },
	data: () => ({
    saveLoader: false,
    heightInCm: null,
    initialName: '',
    initialSurname: '',
    initialEmail: '',
    initialHeight: '',
    initialWeight: '',
    initialPhone: '',
    weightInKg: null,
    weightLimit: '',
    birthdate: '2000-01-01',
    scaleAndTempUnit: 'IMPERIAL',
    glucoseAndCholesterolUnit: 'mg/dl',
    error: '',
		closeWhite: closeWhite,
		valid: true,
    hasError: false,
    enableRPM: 0,
    enableRTM: 0,
    mentalHealth: 0,
	}),
  async mounted () {
    await this.$store.dispatch('verifiers/getVerifiersForDomainHealthInsurance');
    // await this.$store.dispatch('verifiers/getTimeZones');
    this.heightInCm = this.userSettings.Units === 'METRIC' ? this.userData.patientMetadata.heightInCm : parseFloat(fromTo(this.userData.patientMetadata.heightInCm, 'cm', 'ft-us').toFixed(2));
    this.weightInKg = this.userSettings.Units === 'METRIC' ? this.userData.patientMetadata.weightInKg : parseFloat(fromTo(this.userData.patientMetadata.weightInKg, 'kg', 'lb').toFixed(2));
    this.scaleAndTempUnit = (JSON.parse(this.userData.userSettingsJson)).Units;
    this.glucoseAndCholesterolUnit = (JSON.parse(this.userData.userSettingsJson)).Concentration;
    this.initialName = this.userData.firstName;
    this.initialSurname = this.userData.lastName;
    this.initialPhone = this.userData.phone;
    this.initialEmail = this.userData.email;
    this.initialHeight = this.userData.height;
    this.initialWeight = this.userData.weight;
    this.enableRPM = this.userData.isRpmEnabled ? 1 : 0;
    this.enableRTM = this.userData.isRtmEnabled ? 1 : 0;
    this.mentalHealth = this.userData.patientMetadata.mentalHealth ? 1 : 0;
	},
	computed: {
		...mapState({
			detailedUser: (state) => state.users.detailedUser,
			userDetails: (state) => state.authentication.userData,
		}),
		...mapGetters({
      getHealthInsuranceProgram: 'verifiers/getHealthInsuranceProgram',
			roles: 'roles/getRolesForForm',
			userSettings: 'authentication/getUserSettings',
		}),
    smokerCheck: {
      get () {
        if (this.userData === undefined || this.userData.patientMetadata === undefined) {
          return '0';
        } else {
          return this.userData.patientMetadata.isCigaretteSmoker ? '1' : '0';
        }
      },
      set (value) {
        this.userData.patientMetadata.isCigaretteSmoker = value === '1';
      },
    },
    userData () {
      return this.detailedUser?.userData;
    },
	},
	methods:
   {
     checkWeight (val) {
       const maxWeight = this.userSettings.Units === 'METRIC' ? 300 : 660;
       this.weightLimit = val > maxWeight ? this.$t('weight-exceeded') : '';
     },
       formatPhoneNumber (val) {
            this.initialPhone = new AsYouType().input(val);
    },
    checkValidation () {
      if (this.userSettings.Units === 'IMPERIAL') {
        const heightParts = this.heightInCm.split('.');
        if (heightParts.length !== 2 || isNaN(parseInt(heightParts[0])) || isNaN(parseInt(heightParts[1]))) {
          this.error = 'Invalid input. Feet and inches (e.g., 5.5)';
          this.valid = false;
        } else {
          const feet = parseInt(heightParts[0]);
          const inches = parseInt(heightParts[1]);
          if (feet > 6 || (feet === 5 && inches > 9)) {
            this.error = 'Invalid input. Feet and inches (e.g., 5.5)';
            this.valid = false;
          } else {
            this.valid = true;
            this.error = '';
          }
        }
      }
    },
    setImperialOrMetric (unit) {
      this.scaleAndTempUnit = unit;
      this.getPreferences();
    },
    setGlucoseAndCholesterolUnit (unit) {
      this.glucoseAndCholesterolUnit = unit;
      this.getPreferences();
    },
    getPreferences () {
      const preferences = {
        Units: this.scaleAndTempUnit,
        Concentration: this.glucoseAndCholesterolUnit,
      };
      return preferences;
    },

		synchronizeDrawers (drawer) {
			this.$store.commit('SET_PATIENT_DETAILS_DRAWER', drawer);
			this.$store.commit('SET_UPDATE_DETAILS_DRAWER', !this.updateDrawer);
		},
		async updatePatientData () {
      if (this.enableRPM === 1 && this.enableRTM === 1) {
        showErrorAlert(this.$t('patient-can-have-only-one-billing-type'));
        return;
      }
      if (!this.hasError) {
        this.saveLoader = true;
        const body = {
          firstName: this.initialName,
          lastName: this.initialSurname,
          phone: this.initialPhone,
          email: this.initialEmail,
          address: this.userData.address,
          zip: this.userData.zip,
          city: this.userData.city,
          state: this.userData.state,
          country: this.userData.country,
          addressLatitude: this.userData.addressLatitude,
          addressLongitude: this.userData.addressLongitude,
          username: this.userData.email,
          gender: this.detailedUser.userData.gender,
          birthdate: this.userData.birthdateAsString,
          roleId: 5,
          userTimezone: this.userData.userTimezone,
          userSettingsJson: JSON.stringify(this.getPreferences()),
          IsManualMeasurement: this.dataStatus,
          language: this.userData.language,
          id: this.userData.id,
          isRpmEnabled: this.enableRPM === 1,
          isRtmEnabled: this.enableRTM === 1,
        };
        await this.$store.dispatch('users/updateUser', body).then(async (res) => {
          // refactored this to handle the response from the server, not just a success
          // check resFlag from the response
          if (res.resFlag === true) {
            await this.updatePatientMetaData();
            this.saveLoader = false;
            // await this.$store.dispatch('users/getUserById', this.$route.params.id);
            await this.closeDrawer();

            showSuccessAlert(this.$t('patientUpdated'));
          } else {
            showErrorAlert(this.$t('failed-operation'));
          }
        });
      } else {
        showErrorAlert(this.$t('failed-operation'));
      }
		},
    async updatePatientMetaData () {
      const metaDataBody = {
        userId: this.userData.id,
        heightInCm: this.userSettings.Units === 'METRIC' ? parseInt(this.heightInCm) : parseFloat(fromTo(this.heightInCm, 'ft-us', 'cm').toFixed(2)),
        weightInKg: this.userSettings.Units === 'METRIC' ? parseInt(this.weightInKg) : parseFloat(fromTo(this.weightInKg, 'lb', 'kg').toFixed(2)),
        ssn: this.ssn,
        medicalRegistrationNumber: this.userData.patientMetadata.medicalRegistrationNumber,
        patientIdentifier: this.patientIdentifier,
        healthInsurance: this.userData.patientMetadata.healthInsurance,
        memberIdPolicyNumber: this.userData.patientMetadata.memberIdPolicyNumber,
        isCigaretteSmoker: this.smokerCheck === '1',
        mentalHealth: this.mentalHealth === 1,
        birthdate: this.userData.birthdateAsString,
        gender: this.detailedUser.gender = 'M',
        IsManualMeasurement: this.dataStatus,
      };
      // refactored this to handle the response from the server, not just a success
      // check resFlag from the response
      await this.$store.dispatch('patientmetadata/editPatientMetaData', metaDataBody).then(async (res) => {
        if (res.resFlag === true) {
          await this.$store.dispatch('users/getUserById', this.$route.params.id); // reload the user data
        } else {
          showErrorAlert(this.$t('failed-operation'));
        }
      });
    },
		closeDrawer () {
			this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
			this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
			this.$emit('close');
		},
	},
};
</script>
