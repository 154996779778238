<template>
  <v-dialog
    v-model="localDialog"
    max-width="380px"
    transition="dialog-top-transition"
  >
    <v-card class="pa-7">
        <v-card-title class="d-flex justify-center align-center">
          <v-row dense>
            <v-col class="py-0 mb-1" cols="12">
            <span class="icon-background-alert">
              <v-icon dense color="main_red" size="30px" class="my-0"
              >mdi-alert-outline</v-icon
              >
            </span>
            </v-col>
            <v-col cols="12">
              <span>{{ $t("deleteQuestion") }}</span>
            </v-col>
          </v-row>
      </v-card-title>
      <v-card-text class="text-center">{{
        $t("questionTextForDelete")
      }}</v-card-text>
      <v-card-actions class="mb-2">
        <v-row dense>
          <v-col cols="12">
            <v-btn
              block
              depressed
              color="main_red"
              :loading="loading"
              class="text-color-white"
              @click="deleteInvoice"
              >{{ $t("deleteField") }}</v-btn
            >
          </v-col>
          <v-col cols="12">
            <v-btn
              outlined
              depressed
              color="main_black"
              block
              @click="closeDeleteDialog"
              >{{ $t("cancel") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'AssessmentsDeleteDialog',
  props: ['dialog', 'invoiceId'],
  data () {
    return {
      loading: false,
      localDialog: this.dialog,
      localInvoiceId: this.invoiceId,
    };
  },
  mounted () {
    // console.log('invoice : ', this.invoiceId);
  },
  watch: {
    dialog (val) {
      this.localDialog = val;
    },
    invoiceId (val) {
      this.localInvoiceId = val;
    },
    localDialog (val) {
      this.$emit('update:dialog', val);
    },
    localInvoiceId (val) {
      this.$emit('update:invoiceId', val);
    },
  },
  methods: {
    getInvoices () {
      this.loading = true;
      this.$store.dispatch('invoices/getInvoices').then(res => {
        this.loading = false;
      });
    },
    async deleteInvoice () {
      await this.$store
          .dispatch('invoices/deleteInvoiceById', this.localInvoiceId)
          .then((res) => {
            // this.$emit('false', res);
            this.getInvoices();
            this.closeDeleteDialog();
          });
    },
    closeDeleteDialog () {
      this.localDialog = false;
      this.localInvoiceId = '';
    },
  },
};
</script>
