<template>
  <v-card class="mx-auto" elevation="0">
    <v-card-title class="card-header text-left">
      <v-row dense>
        <v-col cols="10">
          <v-toolbar-title>{{ $t("profile-settings") }}</v-toolbar-title>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-icon color="white" @click="closeDrawer">mdi mdi-close</v-icon>
        </v-col>
      </v-row>
    </v-card-title>
    <!-- Role -->
    <v-card-text class="mx-0 px-1">
      <!-- Data -->
      <v-row class="mx-2 mt-5" dense>
        <v-col class="d-flex" cols="12">
          <v-select
            v-model="language"
            :items="languages"
            :item-text="userInfo.language === 'es' ? 'nameEsp' : 'name'"
            item-value="value"
            :label="$t('language')"
            outlined
            dense
            hide-details
            :menu-props="{ offsetY: true }"
          ></v-select>
        </v-col>
        <v-col cols="12" class="text-left font-weight-bold">
          <span>{{ $t("personalInformation") }}</span>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field
            v-model="initialName"
            outlined
            hide-details
            dense
            :label="$t('name')"
            :value="userDetails.firstName"
            required
            :rules="[(rule) => !!rule || '']"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field
            outlined
            v-model="initialLastName"
            hide-details
            dense
            :label="$t('surname')"
            required
            :rules="[(rule) => !!rule || '']"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field
            :readonly="userDetails.roleId === 9"
            outlined
            hide-details
            v-model="userDetails.email"
            dense
            :label="$t('email')"
            :rules="[(rule) => !!rule || '']"
            required
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field
            :readonly="userDetails.roleId === 9"
            outlined
            v-model="userDetails.phone"
            hide-details
            dense
            @input="formatPhoneNumber"
            :label="$t('phone')"
            required
            :rules="[(rule) => !!rule || '']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense class="mx-3">
        <v-btn
          block
          @click="changePasswordSection = !changePasswordSection"
          class="save-button-style my-1"
          hide-details
          dense
          >{{ $t("changeYourPassword") }}</v-btn
        >
      </v-row>
      <v-row dense class="my-3 mx-2" v-if="changePasswordSection">
        <v-col cols="12">
          <v-text-field
            :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="currentPasswordRule"
            v-model="currentPassword"
            :type="showpass ? 'text' : 'password'"
            :label="$t('current-password')"
            value=""
            outlined
            dense
            hide-details
            @click:append="showpass = !showpass"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            :append-icon="showpass2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="newPasswordRule"
            :type="showpass2 ? 'text' : 'password'"
            v-model="newPassword"
            :label="$t('new-password')"
            hint="At least 8 characters"
            value="Pa"
            outlined
            dense
            hide-details
            @click:append="showpass2 = !showpass2"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            :append-icon="showpass3 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="confirmNewPasswordRule"
            :type="showpass3 ? 'text' : 'password'"
            v-model="confirmNewPassword"
            :label="$t('confirm-new-password')"
            hint="At least 8 characters"
            value="Pa"
            outlined
            dense
            hide-details
            @click:append="showpass3 = !showpass3"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn block @click="changePassword" outlined class="capitalize-text">
            {{ this.$t("change") }} {{ this.$t("password") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-2 mt-5" dense v-if="userDetails.roleId !== 9">
        <v-select
          label="Data Filter"
          outlined
          dense
          v-model="dataFilterValue"
          :items="getDataFilterTypes"
          :item-text="
            $i18n.locale === 'en' ? 'longDescription' : 'longDescriptionEsp'
          "
          item-value="shortDescription"
          @input="setDataFilter"
        >
        </v-select>
      </v-row>
      <span
        v-if="dataFilterSelectedAll"
        class="d-flex mx-2 mt-n6 font-size12-5"
        style="color: red"
      >
        <v-icon color="red" size="medium" class="mr-2"
          >mdi-alert-circle-outline</v-icon
        >
        {{ $t("data-filter-select-all") }}
      </span>
      <v-row v-if="userDetails.roleId !== 9" class="mx-2 mt-5" dense>
        <v-col
          cols="12"
          class="text-left mt-n5 text-uppercase font-weight-bold"
        >
          <span>{{ $t("units") }}</span>
        </v-col>
        <v-col cols="12">
          <span class="d-flex mb-2 ml-1">{{ $t("weight-temperature") }}</span>
          <v-btn-toggle
            row
            v-model="scaleAndTempUnit"
            mandatory
            class="white"
            active-class="primary2 white--text"
          >
            <v-btn class="custom-button-style" value="IMPERIAL">{{
              $t("imperial")
            }}</v-btn>
            <v-btn class="custom-button-style" value="METRIC">{{
              $t("metric")
            }}</v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12">
          <span class="d-flex my-2 ml-1">{{ $t("concentration") }}</span>
          <v-btn-toggle
            row
            mandatory
            v-model="glucoseAndCholesterolUnit"
            class="white"
            active-class="primary2 white--text"
          >
            <v-btn
              height="20"
              class="custom-button-style"
              @click="setGlucoseAndCholesterolUnit('mg/dl')"
              value="mg/dl"
              >mg/dL</v-btn
            >
            <v-btn
              class="custom-button-style"
              @click="setGlucoseAndCholesterolUnit('mmol/l')"
              value="mmol/l"
              >mmol/L</v-btn
            >
          </v-btn-toggle>
        </v-col>
      </v-row>
      <!-- Units -->
      <!-- Office/Branch/Hospital selector -->
      <v-col
        cols="12"
        dense
        v-if="
          roleName === 'NURSE' ||
          roleName === 'DOCTOR' ||
          roleName === 'CARE_GIVER'
        "
        class="text-start mt-2 font-weight-bold"
      >
        <!--        <span class="text-uppercase">{{$t('office-selector')}}</span>-->
        <!--        <v-simple-table dense class="border-profile-settings mt-2 pointer">-->
        <!--          <template v-slot:default>-->
        <!--            <tbody>-->
        <!--            <tr-->
        <!--                v-for="thisHospital in userDetails.hospitalsAssignedData"-->
        <!--                :key="thisHospital.id"-->
        <!--                @click="branchSelector(thisHospital)"-->
        <!--            >-->
        <!--              <td v-if="thisHospital.id === userDetails.hospitalAssignedData.id">{{ thisHospital.hospitalName }}</td>-->
        <!--              <td v-else class="gray-color">{{ thisHospital.hospitalName }}</td>-->
        <!--            </tr>-->
        <!--            </tbody>-->
        <!--          </template>-->
        <!--        </v-simple-table>-->
        <!--        <v-data-table height="300"  :footer-props= "getDataTableFooterProps('profileSettingTable')"-->
        <!--                      :items-per-page="-1" dense :items="userDetails.hospitalsAssignedData" :headers="hospitalHeaders" fixed-header>-->
        <!--          <template v-slot:item="{item}">-->
        <!--          <tr>-->
        <!--            <td   @click="branchSelector(item)">-->
        <!--              <span :style="{ color: item.id === userDetails.hospitalAssignedData.id ? 'black' : 'gray' }">{{item.hospitalName}}</span>-->
        <!--            </td>-->
        <!--          </tr>-->
        <!--          </template>-->

        <!--        </v-data-table>-->
      </v-col>
    </v-card-text>
    <v-card-actions @click="branchSelector" class="fixed-card-actions-button" v-if="roleName === 'DOCTOR' || roleName === 'NURSE'">
      <v-row class="mb-0">
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on }">
            <v-btn
                :class="{ 'small-font': isTextTooLong }"
                width="450"
                color="chips"
                v-on="on"
                class="text-wrap mb-5 ml-1"
            >
              <div class="d-flex align-center">
                <img
                    class="mr-1"
                    :src="HospitalWhite"
                    height="22"
                    v-on="on"
                    alt=""
                />
                <span class="white--text">
                        {{
                    userObject.userData?.hospitalAssignedData
                        ?.hospitalName
                  }}</span
                >
              </div>
            </v-btn>
          </template>
          <span class="white-color">{{ $t("office-selector") }}</span>
        </v-tooltip>
      </v-row>
    </v-card-actions>
    <v-card-actions class="fixed-card-actions">
        <v-row class="mb-0">
          <v-col cols="6" class="pr-1">
            <v-btn
                class="capitalize-text text-right"
                elevation="0"
                block
                outlined
                @click="closeDrawer"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-btn class="save-button-style" block @click="update">
              {{ $t("save") }}
            </v-btn>
          </v-col>
        </v-row>
    </v-card-actions>
    <BranchSelectorDialog
        v-if="dialog"
        :dialog="dialog"
        @false="dialog = false"
        @reload="reloadComponent"
    />
  </v-card>
</template>

<script>
import {
  burgerMenu,
  closeBlack,
  rectangle,
  uploadPhoto,
  addBlack,
  hospitalWhite,
} from '@/assets';
import { mapGetters, mapState } from 'vuex';
import { AsYouType } from 'libphonenumber-js';
import { getDataTableFooterProps } from '@/utils/utilities';
import BranchSelectorDialog from '@/views/dialogs/BranchSelectorDialog.vue';
export default {
  name: 'DashboardCoreAppBar',
  components: { BranchSelectorDialog },
  data () {
    return {
      dialog: false,
      HospitalWhite: hospitalWhite,
      hospitalHeaders: [{ text: 'Hospital Name' }],
      dataFilterValue: '',
      dataFilterSelectedAll: false,
      newPassword: '',
      confirmNewPassword: '',
      initialName: '',
      initialLastName: '',
      scaleAndTempUnit: '',
      glucoseAndCholesterolUnit: '',
      currentPassword: '',
      showpass: false,
      showpass2: false,
      showpass3: false,
      language: '',
      languages: [
        {
          name: 'English',
          nameEsp: 'Inglesa',
          value: 'en',
        },
        {
          name: 'Spanish',
          nameEsp: 'Española',
          value: 'es',
        },
      ],
      changePasswordSection: false,
      password: 'Password',
      burgerMenu_icon: burgerMenu,
      closeBlack: closeBlack,
      rectangle: rectangle,
      uploadPhoto: uploadPhoto,
      add: addBlack,
      userInfo: {
        language: '',
        firstName: '',
        userSettingsJson: {
          Units: '',
          Concentration: '',
        },
      },
    };
  },
  beforeDestroy () {
    this.currentPassword = '';
    this.newPassword = '';
    this.confirmNewPassword = '';
  },
  mounted () {
    this.getDataFilterType();
    this.scaleAndTempUnit = this.userSettings.Units;
    this.glucoseAndCholesterolUnit = this.userSettings.Concentration;
    this.addressData();
    this.language = this.$cookies.get('language');
    this.initialName = this.userDetails.firstName;
    this.initialLastName = this.userDetails.lastName;
  },
  computed: {
    ...mapState({
      getDataFilterTypes: (state) => state.verifiers.getDataFilterTypes,
      userDetails: (state) => state.authentication.userData,
      verifiersFromRepo: (state) => state.verifiers.verifiers,
    }),
    ...mapGetters({
      userSettings: 'authentication/getUserSettings',
      roleName: 'authentication/getRole',
      pageHeight: 'pageHeight',
      userObject: 'authentication/userObject',
    }),
    checkRole () {
      return this.roleName === 'DOCTOR' || this.roleName === 'NURSE';
    },
    isTextTooLong () {
      const hospitalName =
        this.userObject.userData?.hospitalAssignedData?.hospitalName || '';
      return hospitalName.length > 28;
    },
    currentPasswordRule () {
      if (this.currentPassword === '') {
        return ['Field should not be empty!'];
      } else if (this.currentPassword.length < 7) {
        return ['Password should have more than 6 characters!'];
      } else {
        return [];
      }
    },
    newPasswordRule () {
      if (this.newPassword === '') {
        return ['Field should not be empty!'];
      } else if (this.newPassword.length < 7) {
        return ['Password should have more than 6 characters!'];
      } else {
        return [];
      }
    },
    confirmNewPasswordRule () {
      if (this.confirmNewPassword === '') {
        return ['Field should not be empty!'];
      } else if (this.confirmNewPassword !== this.newPassword) {
        return ['Passwords do not match', true];
      } else {
        return [];
      }
    },
  },
  methods: {
    reloadComponent () {
      const currentPath = this.$route.path;
      const currentName = this.$route.name;

      if (currentName !== 'Patient Details') {
        this.$router.push('/emptyComponent').then(() => {
          this.$router.push({ path: currentPath, query: { reload: new Date().getTime() } });
        });
      } else {
        this.$router.push('/patients');
      }
    },
    getDataTableFooterProps,
    getDataFilterType () {
      this.$store.dispatch('verifiers/getDataFilterTypes').then(() => {
        // Check if the cookie already exists
        if (this.$cookies.isKey('datafilter')) {
          // If it exists, return its value
          this.dataFilterValue = this.$cookies.get('datafilter');
        } else {
          // If it doesn't exist, set default value
          this.dataFilterValue = 'LAST14'; // or any other default value
        }
      });
    },
    setDataFilter (val) {
      this.dataFilterSelectedAll = val === 'ALL';
      this.$cookies.set('datafilter', val, '6m');
    },
    formatPhoneNumber (val) {
      this.userDetails.phone = new AsYouType().input(val);
    },
    closeDrawer () {
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
      this.currentPassword = [];
      this.newPassword = [];
      this.confirmNewPassword = [];
      this.changePasswordSection = false;
    },
    async addressData () {
      this.address_info = {
        address: this.userDetails.address,
        postal_code: this.userDetails.zip,
        city: this.userDetails.city,
        state: this.userDetails.state,
        country: this.userDetails.country,
        lat: this.userDetails.addressLatitude,
        lng: this.userDetails.addressLongitude,
      };
      this.timezone = this.userDetails.userTimezone;
    },
    async locationDetails () {
      if (this.userDetails.address) {
        const geoLocation = await this.$store.dispatch(
          'googleAPI/getGeoLocation',
          this.userDetails.address,
        );
        const addressComponents = geoLocation.address_components;
        this.address_info.lat = geoLocation.geometry.location.lat;
        this.address_info.lng = geoLocation.geometry.location.lng;
        this.formattedAddress = geoLocation.formatted_address;
        this.address_info.address = this.formattedAddress;
        addressComponents.forEach((ac) => {
          if (ac.types.includes('administrative_area_level_1')) {
            this.address_info.state = ac.long_name;
          }
          if (ac.types.includes('locality')) {
            this.address_info.city = ac.long_name;
          }
          if (ac.types.includes('country')) {
            this.address_info.country = ac.long_name;
          }
          if (ac.types.includes('postal_code')) {
            this.address_info.postal_code = ac.long_name;
          }
        });
        if (this.address_info.lat !== null) {
          const tz = await this.timeZone(
            this.address_info.lat,
            this.address_info.lng,
          );
          if (tz) {
            this.timezone = tz.zoneName;
          }
        }
      }
    },
    async timeZone (lat, lng) {
      const object = {
        lat: lat,
        lng: lng,
      };
      const timeZone = await this.$store.dispatch(
        'googleAPI/getTimeZone',
        object,
      );
      return timeZone;
    },
    setImperialOrMetric (unit) {
      this.scaleAndTempUnit = unit;
      this.changePreferences();
    },
    setGlucoseAndCholesterolUnit (unit) {
      this.glucoseAndCholesterolUnit = unit;
      this.changePreferences();
    },
    changePreferences () {
      const body = {
        scaleAndTempUnit: this.scaleAndTempUnit,
        glucoseAndCholesterolUnit: this.glucoseAndCholesterolUnit,
      };
      this.$store.dispatch('metrics/setMetric', body);
    },
    async update () {
      const userSettingsBody = {
        Units: this.scaleAndTempUnit,
        Concentration: this.glucoseAndCholesterolUnit,
      };
      this.snackStatus = false;
      const body = {
        id: this.userDetails.id,
        firstName: this.initialName,
        lastName: this.initialLastName,
        phone: this.userDetails.phone.split(' ').join(''),
        email: this.userDetails.email,
        address: this.address_info.address,
        zip: this.address_info.postal_code,
        city: this.address_info.city,
        state: this.address_info.state,
        country: this.address_info.country,
        addressLatitude: this.address_info.lat,
        addressLongitude: this.address_info.lng,
        userTimezone: this.timezone,
        username: this.userDetails.username,
        status: this.userDetails.status,
        gender: this.userDetails.gender,
        roleId: this.userDetails.roleId,
        birthdate: this.birthdateAsString,
        userSettingsJson: JSON.stringify(userSettingsBody),
      };
      await this.$store
        .dispatch('users/updateUser', body)
        .then((res) => {
          location.reload();
          this.$emit('false');
          this.snackStatus = true;
          this.snackColor = 'teal darken-2';
          this.title = this.$t('profileUpdatedSuccessfully');
          this.$store.dispatch('authentication/changePrefenrences', res.data);
          // if we don't change the password, we don't call the API
          if (this.changePasswordSection === true) {
            // this.changePassword();
          }
          this.closeDrawer();
        })
        .catch(() => {
          this.snackStatus = true;
          this.snackColor = 'deep-orange darken-4';
          this.title = this.$t('profileCouldNotUpdate');
        });
    },
    async changePassword () {
      const body = {
        currentPassword: this.currentPassword,
        newPassword: this.confirmNewPassword,
      };
      await this.$store.dispatch('users/changePassword', body).then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          if (res.resFlag) {
            this.$store.commit('alerts/add', {
              id: 'changePassword',
              type: 'success',
              color: 'main_green',
              message: res.msg,
            });
            this.$store.dispatch('alerts/clearWithDelay', this.delay);
          } else {
            this.$store.commit('alerts/add', {
              id: 'changePassword',
              type: 'error',
              color: 'main_red',
              message: res.msg,
            });
            this.$store.dispatch('alerts/clearWithDelay', this.delay);
          }
        }
        // if (res.resFlag) {
        // 	this.changePasswordSection = false;
        // 	this.currentPassword = '';
        // 	this.newPassword = '';
        // 	this.confirmNewPassword = '';
        // 	this.snackStatus = true;
        // 	this.snackColor = 'teal darken-2';
        // 	this.title = this.$t('passwordChangedSuccessfully');
        // }
      });
      // .catch(() => {
      // 	this.changePasswordSection = false;
      // 	this.snackStatus = true;
      // 	this.snackColor = 'deep-orange darken-4';
      // 	this.title = this.$t('passwordCouldNotBeChanged');
      // 	this.currentPassword = '';
      // 	this.newPassword = '';
      // 	this.confirmNewPassword = '';
      // });
    },
    // async changePassword () {
    // 	const body = {
    // 		currentPassword: this.currentPassword,
    // 		newPassword: this.confirmNewPassword,
    // 	};
    // 	await this.$store
    // 		.dispatch('users/changePassword', body)
    // 		.then((res) => {
    // 			if (res.resFlag) {
    // 				this.changePasswordSection = false;
    // 				this.currentPassword = '';
    // 				this.newPassword = '';
    // 				this.confirmNewPassword = '';
    // 				this.snackStatus = true;
    // 				this.snackColor = 'teal darken-2';
    // 				this.title = this.$t('passwordChangedSuccessfully');
    // 			}
    // 		})
    // 		.catch(() => {
    // 			this.changePasswordSection = false;
    // 			this.snackStatus = true;
    // 			this.snackColor = 'deep-orange darken-4';
    // 			this.title = this.$t('passwordCouldNotBeChanged');
    // 			this.currentPassword = '';
    // 			this.newPassword = '';
    // 			this.confirmNewPassword = '';
    // 		});
    // },
    branchSelector () {
      if (this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
        this.dialog = true;
        this.closeDrawer();
      } else {
        this.dialog = false;
      }
    },
  },
  watch: {
    language (val) {
      var tempcookie;
      switch (val) {
        case 'en':
          tempcookie = 'ENG';
          this.$cookies.set('language', 'en', '6m');
          break;
        case 'es':
          tempcookie = 'ESP';
          this.$cookies.set('language', 'es', '6m');
          break;
      }
      this.$i18n.locale = this.$cookies.get('language');
      var body = {
        Language: tempcookie,
      };
      this.$store.dispatch('users/changemyLanguage', body);
    },
  },
  async loader () {
    if (this.loader !== null) {
      const l = this.loader;
      this[l] = !this[l];

      await this.update()
        .then(() => {
          this[l] = false;
        })
        .catch(() => {
          this[l] = false;
        });

      this.loader = null;
    }
  },
  'userDetails.phone': function (val) {
    if (val !== undefined) {
      this.userDetails.phone = new AsYouType().input(val);
    }
  },
};
</script>
